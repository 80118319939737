import { render, staticRenderFns } from "./SubscriptionsAddSecurity.vue?vue&type=template&id=8d6c6c3a&scoped=true&"
import script from "./SubscriptionsAddSecurity.vue?vue&type=script&lang=ts&"
export * from "./SubscriptionsAddSecurity.vue?vue&type=script&lang=ts&"
import style0 from "./SubscriptionsAddSecurity.vue?vue&type=style&index=0&id=8d6c6c3a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d6c6c3a",
  null
  
)

export default component.exports