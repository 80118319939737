
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { JpuDTO, SubscriptionActionRequest, SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { AnimatedContentCardProps } from "@/types/content/animatedContentCard";
import { LoadingMaskProps } from "@/types/loadingMask";
import { FlatSubscription } from "@/types/localtypes";
import { ViewContext } from "@/types/services/app";
import { MenuConfiguration, MenuItemProps } from "@/types/services/menus";
import LoadingMask from "@/components/common/LoadingMask.vue";
import AnimatedContentCard from "@/components/content/AnimatedContentCard.vue";
import PageSection from "@/components/layout/PageSection.vue";
import PageView from "@/components/layout/PageView.vue";

@Component({
  name: "SubscriptionsAddSecurity",
  components: {
    PageView,
    PageSection,
    LoadingMask,
    AnimatedContentCard,
  },
})
export default class SubscriptionsAddSecurity extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("setMenuConfiguration", { namespace: "application" })
  setMenuConfiguration!: (menuConfiguration: MenuConfiguration) => void;
  @Action("setViewContext", { namespace: "application" })
  setViewContext!: (viewContext: ViewContext) => void;
  @Getter("menuConfiguration", { namespace: "application" })
  menuConfiguration!: MenuConfiguration;
  @Getter("subscriptions", { namespace: "subscriptions" })
  subscriptions!: SubscriptionDTO[];
  accountNumber!: FlatSubscription["accountNumber"];
  subscription: FlatSubscription | null = null;
  price: string | null = null;
  contentCards: AnimatedContentCardProps[] = [];
  videoHeight = 200;
  contentHeight: number = 100;
  contentHeightUnit: "px" | "%" = "%";

  @Watch("subscription")
  async onSubscriptionChanged() {
    this.setMenuConfiguration(await this.defineMenuConfiguration());
    this.setViewContext(this.defineViewContext());
  }

  get actionName() {
    if (this.subscription && (this.subscription.meta.isEnterprise || this.subscription.meta.isEnterpriseX)) {
      return "Get A Quote";
    }
    return "Upgrade";
  }

  defineViewContext(): ViewContext {
    return this.subscriptions && this.subscription
      ? { subscriptionMetas: this.subscriptions.map(s => s.meta), currentSubscriptionMeta: this.subscription.meta }
      : {};
  }
  get nbCardsPerRows() {
    return this.isMobile ? 1 : this.isTablet ? 2 : 3;
  }

  get prefixTitle() {
    if (this.subscription) {
      const meta = this.subscription.meta;
      return meta.isEnterprise ? "Upgrade your plan with" : "Upgrade to";
    }
    return "";
  }

  get isEnterprise() {
    if (this.subscription) {
      const meta = this.subscription.meta;
      return meta.isEnterprise;
    }
    return false;
  }

  get isTablet() {
    return this.$mq === "tablet";
  }
  get isMobile() {
    return this.$mq === "mobile";
  }
  get isLaptop() {
    return this.$mq === "laptop";
  }
  get isLaptopXL() {
    return this.$mq === "laptopXL";
  }

  get titleWrapperStyles() {
    // const flexVal: number = this.isLaptop ? 1.7 : this.isLaptopXL ? 1.3 : this.isMobile ? 0 : 1;
    const flexVal: number = this.isLaptop ? 1.5 : this.isTablet ? 1.8 : this.isMobile ? 0 : 1;
    return {
      flex: flexVal,
    };
  }

  get sectionClasses() {
    const classes = [];
    classes.push((!this.isMobile && "noscroll") || "");
    return classes;
  }
  get contentWrapperClasses() {
    const classes = [];
    classes.push((!this.isMobile && "scrollable") || "");
    return classes;
  }

  get contentWrapperStyles() {
    return {
      height: this.contentHeight + this.contentHeightUnit,
    };
  }

  get cardStyles() {
    const flexPercent = 100 / this.nbCardsPerRows;
    return {
      flex: flexPercent + "%",
    };
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get isLoading() {
    return !this.price || !this.subscription;
  }

  get serverName() {
    return (this.subscription && this.subscription.serverName) || "";
  }

  async defineMenuConfiguration(): Promise<MenuConfiguration> {
    this.globalBus.$emit("onMenuConfigurationChange");
    if (this.subscription) {
      const meta = this.subscription.meta;
      return await this.$jfMenus.defineContextualMenuConfiguration(
        this.serverName,
        meta,
        (menuId, serverName, tooltipContent, tooltipHasSubmitAction) => {
          this.globalBus.$emit(
            "onMenuTooltipClick",
            menuId,
            serverName,
            tooltipContent,
            tooltipHasSubmitAction,
            this.$jfSubscriptions.getSubscriptionRequestReason(menuId, meta),
          );
        },
      );
    }
    return {};
  }

  notFound() {
    this.$router.replace({ path: "/404" });
  }

  notAllowed() {
    this.$router.replace({ path: "/" });
  }

  get nativeArtifactoryIntegrationCard(): AnimatedContentCardProps {
    return {
      title: "Native Artifactory Integration",
      content: [
        "The only component analysis tool that adds value to Artifactory by enriching artifacts with metadata protecting your software from potential vulnerabilities and license violations",
      ],
      videoUrl: this.$jfVideos.get("Native_Artifactory_Integration_R3.mp4"),
      videoPoster: this.$jfImages.get("poster_Native_Artifactory_Integration_R3.jpg"),
      height: this.videoHeight,
    };
  }

  get universalSecurityCard(): AnimatedContentCardProps {
    return {
      title: "Universal Security & Compliance",
      content: [
        "Performs component analysis for all major package formats searching for vulnerabilities and license violations. Also scans all the layers and dependencies included in your Docker images",
      ],
      videoUrl: this.$jfVideos.get("universal_R10.mp4"),
      videoPoster: this.$jfImages.get("poster_universal_R10.jpg"),
      height: this.videoHeight,
    };
  }

  get vulnDBCard(): AnimatedContentCardProps {
    return {
      title: "VulnDB Integrated",
      content: [
        "Protects against open source security vulnerabilities using the most comprehensive and timely vulnerability intelligence in the industry",
      ],
      videoUrl: this.$jfVideos.get("Intitlement_R2.mp4"),
      videoPoster: this.$jfImages.get("poster_Entitlement_R2.jpg"),
      height: this.videoHeight,
    };
  }

  get radicalTransparencyCard(): AnimatedContentCardProps {
    return {
      title: "Radical Transparency",
      content: [
        "Deep recursive scanning provides insight into your components, creating a dependency graph, showing the impact that any vulnerability or license violation issue, has on all your software artifacts",
      ],
      videoUrl: this.$jfVideos.get("Radical_Transpaency_R2.mp4"),
      videoPoster: this.$jfImages.get("poster_Radical_Transpaency_R2.jpg"),
      height: this.videoHeight,
    };
  }
  get businessAgilityCard(): AnimatedContentCardProps {
    return {
      title: "Business Agility and Scalability",
      content: [
        "We manage and host your choice of AWS, Azure and GCP, maintain and scale the infrastructure and provide automated server backups with free updates, data recovery and guaranteed uptime",
      ],
      videoUrl: this.$jfVideos.get("Scales-to-infinity-Gif.mp4"),
      videoPoster: this.$jfImages.get("poster_scales-to-infinity.jpg"),
      height: this.videoHeight,
    };
  }

  get levelSupportCard(): AnimatedContentCardProps {
    return {
      title: "Unbeatable 24/7 R&D Level Support",
      content: ["We provide around the clock help from the best support team in the industry"],
      videoUrl: this.$jfVideos.get("24.7_Support_R2.mp4"),
      videoPoster: this.$jfImages.get("poster_support24.jpg"),
      height: this.videoHeight,
    };
  }

  async loadContent() {
    // we need subscription before price to calculate right prox with pipelines price.
    await this.fetchSubscription();
    await this.loadStartingPrice();
    this.contentCards = [
      this.nativeArtifactoryIntegrationCard,
      this.universalSecurityCard,
      this.vulnDBCard,
      this.radicalTransparencyCard,
      this.businessAgilityCard,
      this.levelSupportCard,
    ];
    this.contentHeight = (document.getElementsByClassName("ex-page-wrapper")[0] as HTMLDivElement).clientHeight;
    this.contentHeightUnit = "px";
  }

  get hasPipelinesAvailable() {
    return this.subscription && this.subscription.meta.hasPipelines && !this.subscription.meta.isPipelinesBlocked;
  }

  async loadStartingPrice() {
    const types = await this.$jfSubscriptions.getSubscriptionTypes(this.accountNumber);
    const monthlyProXType = types.find(type => type.paymentType === "MONTHLY_PROX");
    const pipelinesProXType = types.find(type => type.optionType === "PIPELINES_PRO_X");

    if (monthlyProXType) {
      this.price = this.$utils.round(parseInt(monthlyProXType.price), 0);

      if (pipelinesProXType && this.hasPipelinesAvailable) {
        this.price = this.$utils.round(parseInt(monthlyProXType.price) + parseInt(pipelinesProXType.price), 0);
      }
    } else {
      this.$log.error("'MONTHLY_PROX' not in subscriptionTypes");
    }
  }

  async fetchSubscription() {
    try {
      const subscription = await this.$jfSubscriptions.getSubscription(this.accountNumber);
      const meta = subscription.meta;
      const subscriptions = await this.$jfSubscriptions.getSubscriptions();
      const subscriptionMetas = subscriptions.map(s => s.meta);
      if (
        !this.$jfSubscriptions.isXrayNewForCustomer(subscriptionMetas) ||
        !this.$jfSubscriptions.isAddXrayPageReachable(meta)
      ) {
        return this.notAllowed();
      }
      this.subscription = this.$jfSubscriptions.transformSubscription(subscription);
    } catch (error) {
      if (error.httpStatusText === 500) {
        this.$jfNotification.error({ text: this.$jfMessages.app_something_went_wrong });
      } else {
        this.notFound();
      }
    }
  }

  async sendSubscriptionRequest(
    id: MenuItemProps["id"],
    serverName: JpuDTO["serverName"],
    reason: SubscriptionActionRequest["reason"],
  ) {
    await this.$jfSubscriptions.sendSubscriptionActionRequest({
      marketoCookie: this.$jfMarketo.getCookie(),
      actionType: "upgrade",
      serverName: serverName,
      reason: reason,
    });
  }

  handleUpgradeClick() {
    const upgradeMenuId: MenuItemProps["id"] = "upgrade";
    const upgradeMenuTooltip =
      this.menuConfiguration.tooltips && this.menuConfiguration.tooltips.find(t => t.id === upgradeMenuId);
    if (this.subscription && this.subscription.meta.isEnterprise) {
      const enterpriseMeta = this.subscription.meta;
      const reason = this.$jfSubscriptions.getSubscriptionRequestReason(upgradeMenuId, enterpriseMeta);
      this.sendSubscriptionRequest(upgradeMenuId, this.serverName, reason);
      this.$jfNotification.success({ text: this.$jfMessages.subscriptions_upgrade_error_xray_cant_upgrade });
      return;
    }
    if (this.subscription && upgradeMenuTooltip) {
      const meta = this.subscription.meta;
      // the subscription cannot access to upgrade page and should see a explanation modal
      const tooltipContent = this.$jfMenus.getTooltipConfig(upgradeMenuId, meta).content;
      const reason = this.$jfSubscriptions.getSubscriptionRequestReason(upgradeMenuId, meta);
      const tooltipHasSubmitAction = upgradeMenuTooltip.tooltipHasAction;
      if (this.subscription.meta.isEnterprisePlus) {
        this.sendSubscriptionRequest(upgradeMenuId, this.serverName, reason);
        this.$jfNotification.success({ text: this.$jfMessages.subscriptions_upgrade_error_xray_cant_upgrade });
        return;
      }
      this.$jfModal.confirm({
        body: tooltipContent,
        buttonsText: {
          confirm: tooltipHasSubmitAction ? "Contact Me" : "Close",
        },
        onCancel: () => true,
        onConfirm: () => {
          this.sendSubscriptionRequest(upgradeMenuId, this.serverName, reason);
          this.$jfNotification.success({ text: this.$jfMessages.menu_tooltip_action_success });
          return true;
        },
        forceDisplayCancelIcon: false,
        displayCancelButton: tooltipHasSubmitAction,
        footerBorder: false,
      });
    } else {
      // the subscription can access to upgrade page
      this.$router.push({ path: "upgrade" });
    }
  }
  mounted() {
    this.$jfModal.closeAll();
    const queryParam = parseInt(this.$route.params.accountNumber) as SubscriptionDTO["accountNumber"];
    if (Number.isNaN(queryParam)) {
      this.notFound();
    } else {
      this.accountNumber = queryParam;
      this.loadContent();
    }
  }
}
